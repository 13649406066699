<template>
  <div>
    <div class="container">
      <div class="row ">
        <div class="col-6 align-content-center justify-content-center">
          <h1>Chastotka™</h1>
          <p>Извлекает токены и их частотность из массива текстов</p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="p-4">
        <!-- <form> -->
        <div class="row">
          <div class="col-4">
            <!-- <div class="row"> -->
            <form enctype="multipart/form-data" novalidate>
              <div class="dropbox">
                <input type="file" name="file1" :disabled="isSaving()" @change="filesChange($event.target.files)"
                  class="input-file" />
                <p v-if="isInitial()">Файл не загружен</p>
                <p v-if="isSaving()">Грузим файл...</p>
                <p v-if="isSuccess()">Файл получили!</p>
                <p v-if="isFailed()">
                  Что-то пошло не так...<br />
                  <span v-if="this.currentError != ''">Покажите админу эту информацию:
                    {{ this.currentError }}</span>
                </p>
              </div>
            </form>
            <!-- </div> -->
          </div>
          <!-- <form> -->

          <div class="col-4">
            <!-- <div class="row"> -->
            <file-proc-input id="text_name" inputKind="text" v-model="input_api.txt" label="Название поля с текстом"
              placeholder="например, Текст" />
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <a id="div-with-loading" class="btn btn-info vs-con-loading__container ml-3" @click="sendData"
            :style="[(filesLoaded ? calcInProgress : true) ? { 'pointer-events': 'none' } : {}]">
            Отправить данные на расчет</a>
          <a @click="downloadFile" class="btn btn-outline-primary ml-3">
            <download-icon class="fea icon-sm"></download-icon>Скачать последние
            результаты <br />
            <small>{{ this.lastEditTxt }} </small></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon } from "vue-feather-icons";
import FileProcInput from "./fileProcInput.vue";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  components: {
    MessageCircleIcon,
    BookIcon,
    DownloadIcon,
    FileProcInput,
  },
  name: "Chastotka",
  data() {
    return {
      // true_hostname: (process.env.VUE_APP_TARGET_PLATFORM == "Google") ? this.$hostname : "https://ai.sber-sip.ru/api",
      true_hostname: this.$hostname_ba_macro,
      input_api: {
        txt: "Текст",
        userId: store.state.user_id,
      },
      request_api: {
        userId: store.state.user_id,
      },
      currentStatus: STATUS_INITIAL,
      currentError: "",
      tableShow: false,
      showStatus: false,
      lastEdit: "",
      status: {
        rows_in: 0,
        rows_out: 0,
      },
      token: "",
      calcInProgress: false,
      excelResults: [],
      refreshTime: 1,
    };
  },

  computed: {
    filesLoaded: function () {
      return this.currentStatus > 1
    },
    lastEditTxt: function () {
      if (this.lastEdit == "") {
        return "Отчетов пока нет";
      } else {
        return `Последний отчет сделан: ${this.lastEdit}`;
      }
    },
  },
  methods: {
    async pollLastSaved() {
      const url = `${this.true_hostname}/chastotka/checkLastEdited/${store.state.user_id
        }`;

      const ret = await this.axios
        .get(url)
        // get data
        .then((x) => x.data.path);
      // add url field
      console.log(ret);
      this.lastEdit = ret;
      return ret;
    },
    isInitial: function () {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving: function () {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess: function () {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed: function () {
      return this.currentStatus === STATUS_FAILED;
    },
    filesChange(fileList) {
      this.currentStatus = STATUS_SAVING;
      // handle file changes
      var formData = new FormData();

      if (!fileList.length) return;
      // append the files to FormData
      formData.append("file", fileList[0]);
      // save it
      this.save(formData);
    },

    save(formData) {
      // upload data to the server
      this.upload(formData)
        .then((x) => {
          console.log(x);
          if (x == 200) {
            this.currentStatus = STATUS_SUCCESS;
          } else {
            this.currentStatus = STATUS_FAILED;
            this.currentError = `Error ${x}`;
          }
        })
        .catch((err) => {
          this.currentError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },

    upload(formData) {
      const url = `${this.true_hostname}/chastotka/uploadfile/${store.state.user_id}`;
      const ret = this.axios
        .post(url, formData)
        .then((x) => x.data);
      console.log(ret);
      return ret;
    },

    sendData() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.5,
        opacity: 0.1,
      });
      this.calcInProgress = true;
      this.axios({
        method: "post",
        url: `${this.true_hostname}/chastotka/process_data`,
        data: this.input_api,
      })
        .then((res) => {
          console.log("successful processing");
          console.log(res.data);
          this.pollLastSaved();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        })
        .catch((err) => {
          console.log("unsuccessful processing");
          console.error(err);
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        });
    },

    downloadFile() {
      this.input_api.userId = store.state.userId;

      this.axios({
        method: "post",
        url: `${this.true_hostname}/chastotka/fetch_results`,
        data: this.request_api,
        // data: {"userId": this.input_api.userId},
        responseType: "blob",
      }).then(function (response) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const aEle = document.createElement("a"); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link
        aEle.href = href;
        aEle.download = "report.xlsx"; // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object
      });
    },
  },
  beforeMount() {
    this.pollLastSaved();
  },
};
</script>


<style scoped></style>
